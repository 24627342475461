<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="135"
    height="40"
    viewBox="0 0 135 40"
    fill="none"
  >
    <g clip-path="url(#clip0_2365_1787)">
      <mask
        id="mask0_2365_1787"
        style="mask-type:luminance"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="135"
        height="40"
      >
        <path
          d="M135 0H0V40H135V0Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_2365_1787)">
        <path
          d="M124.249 0.00013H10.7562C10.3425 0.00013 9.93375 0.00013 9.52114 0.00213C9.17576 0.00413 8.83312 0.00994 8.48442 0.01483C7.72687 0.0227318 6.97111 0.0818063 6.2237 0.19154C5.47733 0.303663 4.75435 0.515046 4.07919 0.81854C3.40487 1.1246 2.78872 1.5223 2.25309 1.9972C1.71466 2.47077 1.26583 3.01815 0.923871 3.61829C0.580967 4.21724 0.343196 4.85907 0.21877 5.52161C0.0931615 6.18332 0.0255704 6.85265 0.0166034 7.52361C0.00613403 7.83021 0.00502843 8.13783 -0.000488281 8.44447V31.5587C0.00502843 31.8692 0.00613403 32.17 0.0166034 32.4806C0.0255732 33.1516 0.0931643 33.8209 0.21877 34.4825C0.342852 35.1455 0.580637 35.7877 0.923871 36.3868C1.26568 36.985 1.71457 37.5302 2.25309 38.0011C2.78669 38.4781 3.40324 38.8761 4.07919 39.1798C4.75434 39.4841 5.47725 39.6968 6.2237 39.8106C6.97124 39.9195 7.72692 39.9786 8.48442 39.9874C8.83312 39.9942 9.17576 39.9981 9.52114 39.9981C9.93374 40.0001 10.3425 40.0001 10.7562 40.0001H124.249C124.655 40.0001 125.067 40.0001 125.472 39.9981C125.816 39.9981 126.169 39.9942 126.512 39.9874C127.268 39.9791 128.023 39.92 128.769 39.8106C129.518 39.696 130.243 39.4834 130.921 39.1798C131.597 38.8759 132.213 38.478 132.746 38.0011C133.283 37.5284 133.733 36.9836 134.079 36.3868C134.42 35.7872 134.655 35.1451 134.777 34.4825C134.903 33.8208 134.973 33.1516 134.987 32.4806C134.991 32.17 134.991 31.8692 134.991 31.5587C135 31.1954 135 30.8341 135 30.4649V9.53626C135 9.17005 135 8.80677 134.991 8.44447C134.991 8.13783 134.991 7.83021 134.987 7.52357C134.973 6.85255 134.903 6.18337 134.777 5.52157C134.655 4.85941 134.419 4.21763 134.079 3.61825C133.383 2.41533 132.278 1.43616 130.921 0.81845C130.243 0.515697 129.517 0.30437 128.769 0.19145C128.023 0.0812328 127.269 0.0221378 126.512 0.01469C126.169 0.00981 125.816 0.00395 125.472 0.002C125.067 0 124.655 0.00013 124.249 0.00013Z"
          fill="CurrentColor"
        />
        <path
          d="M9.52662 39.125C9.18289 39.125 8.84746 39.1211 8.50643 39.1143C7.79995 39.1061 7.09513 39.0516 6.39774 38.9512C5.74745 38.8519 5.1175 38.6673 4.52867 38.4033C3.94523 38.1415 3.41308 37.7983 2.95262 37.3867C2.4855 36.98 2.09659 36.5082 1.80132 35.9902C1.5028 35.4688 1.2962 34.9099 1.18873 34.333C1.07267 33.7131 1.00988 33.0863 1.00089 32.458C0.993739 32.2471 0.984375 31.5449 0.984375 31.5449V8.44434C0.984375 8.44434 0.994348 7.75293 1.00095 7.5498C1.00955 6.92248 1.07198 6.29665 1.18769 5.67773C1.29536 5.09925 1.50212 4.53875 1.8008 4.01563C2.09499 3.49794 2.48173 3.02586 2.94605 2.61768C3.40984 2.20562 3.94368 1.8606 4.52811 1.59521C5.1156 1.33209 5.74436 1.14873 6.39333 1.05127C7.09301 0.949836 7.80028 0.894996 8.50921 0.88721L9.52717 0.875H125.467L126.497 0.8877C127.199 0.895099 127.9 0.94945 128.593 1.05029C129.249 1.14898 129.884 1.33362 130.479 1.59814C131.649 2.13299 132.602 2.97916 133.203 4.01807C133.497 4.53758 133.701 5.09351 133.807 5.66699C133.924 6.29099 133.989 6.92174 134.003 7.5542C134.006 7.8374 134.006 8.1416 134.006 8.44434C134.015 8.81934 134.015 9.17627 134.015 9.53613V30.4648C134.015 30.8281 134.015 31.1826 134.006 31.54C134.006 31.8652 134.006 32.1631 134.002 32.4697C133.989 33.0909 133.924 33.7104 133.809 34.3232C133.704 34.9043 133.498 35.4675 133.2 35.9932C132.902 36.5056 132.516 36.9733 132.054 37.3789C131.593 37.7927 131.06 38.1379 130.475 38.4014C129.883 38.6674 129.248 38.8527 128.593 38.9512C127.896 39.0522 127.191 39.1067 126.485 39.1143C126.154 39.1211 125.808 39.125 125.472 39.125L124.249 39.127L9.52662 39.125Z"
          fill="CurrentColor"
        />
        <path
          d="M32.7685 20.3013C32.7793 19.4667 33.001 18.6483 33.413 17.9223C33.825 17.1964 34.414 16.5864 35.1251 16.1493C34.6733 15.5041 34.0774 14.9732 33.3846 14.5986C32.6917 14.2241 31.9211 14.0162 31.1339 13.9916C29.4547 13.8153 27.8267 14.9964 26.971 14.9964C26.0987 14.9964 24.7812 14.0091 23.3625 14.0383C22.4448 14.0679 21.5505 14.3348 20.7666 14.8128C19.9827 15.2909 19.3361 15.9638 18.8896 16.7661C16.9556 20.1146 18.3982 25.0356 20.2508 27.7422C21.1777 29.0676 22.261 30.548 23.6784 30.4955C25.0655 30.438 25.5835 29.611 27.2578 29.611C28.9166 29.611 29.4026 30.4955 30.8488 30.4621C32.3372 30.438 33.2749 29.1309 34.1693 27.793C34.8353 26.8486 35.3478 25.8049 35.6877 24.7005C34.823 24.3348 34.0851 23.7226 33.566 22.9403C33.0469 22.158 32.7695 21.2402 32.7685 20.3013Z"
          fill="white"
        />
        <path
          d="M30.0371 12.2103C30.8486 11.2361 31.2484 9.98397 31.1516 8.71973C29.9118 8.84996 28.7665 9.44251 27.944 10.3793C27.5419 10.837 27.2339 11.3694 27.0376 11.9462C26.8414 12.523 26.7607 13.1328 26.8003 13.7407C27.4205 13.7471 28.034 13.6127 28.5946 13.3476C29.1553 13.0825 29.6485 12.6937 30.0371 12.2103Z"
          fill="white"
        />
        <path
          d="M50.3018 27.1397H45.5684L44.4316 30.4961H42.4268L46.9102 18.0781H48.9932L53.4766 30.4961H51.4375L50.3018 27.1397ZM46.0586 25.5908H49.8106L47.961 20.1436H47.9092L46.0586 25.5908Z"
          fill="white"
        />
        <path
          d="M63.1592 25.9704C63.1592 28.7839 61.6533 30.5915 59.3809 30.5915C58.8052 30.6216 58.2327 30.489 57.7289 30.2089C57.2251 29.9288 56.8104 29.5124 56.5323 29.0075H56.4893V33.4919H54.6309V21.4431H56.4297V22.949H56.4639C56.7549 22.4465 57.1766 22.0323 57.6842 21.7504C58.1918 21.4686 58.7664 21.3296 59.3467 21.3484C61.6445 21.3484 63.1592 23.1648 63.1592 25.9704ZM61.2491 25.9704C61.2491 24.1374 60.3018 22.9324 58.8565 22.9324C57.4365 22.9324 56.4815 24.1628 56.4815 25.9704C56.4815 27.7947 57.4365 29.0163 58.8565 29.0163C60.3018 29.0163 61.2491 27.8201 61.2491 25.9704Z"
          fill="white"
        />
        <path
          d="M73.1241 25.9704C73.1241 28.7839 71.6182 30.5915 69.3457 30.5915C68.7701 30.6216 68.1976 30.489 67.6938 30.2089C67.19 29.9288 66.7753 29.5124 66.4971 29.0075H66.4541V33.4919H64.5957V21.4431H66.3945V22.9489H66.4287C66.7197 22.4465 67.1414 22.0323 67.649 21.7504C68.1567 21.4686 68.7312 21.3296 69.3115 21.3484C71.6094 21.3484 73.1241 23.1648 73.1241 25.9704ZM71.2139 25.9704C71.2139 24.1374 70.2666 22.9323 68.8213 22.9323C67.4014 22.9323 66.4463 24.1628 66.4463 25.9704C66.4463 27.7947 67.4014 29.0163 68.8213 29.0163C70.2666 29.0163 71.2139 27.82 71.2139 25.9704Z"
          fill="white"
        />
        <path
          d="M79.71 27.0362C79.8477 28.2676 81.044 29.0762 82.6787 29.0762C84.2452 29.0762 85.3721 28.2676 85.3721 27.1572C85.3721 26.1934 84.6924 25.6162 83.083 25.2207L81.4737 24.833C79.1934 24.2822 78.1348 23.2158 78.1348 21.4854C78.1348 19.3428 80.002 17.8711 82.6534 17.8711C85.2774 17.8711 87.0762 19.3428 87.1367 21.4854H85.2607C85.1484 20.2461 84.124 19.4981 82.627 19.4981C81.1299 19.4981 80.1055 20.2549 80.1055 21.3565C80.1055 22.2344 80.7598 22.751 82.3604 23.1465L83.7285 23.4824C86.2764 24.0849 87.335 25.1084 87.335 26.9248C87.335 29.248 85.4844 30.7031 82.541 30.7031C79.7871 30.7031 77.9277 29.2822 77.8076 27.0361L79.71 27.0362Z"
          fill="white"
        />
        <path
          d="M91.3457 19.2998V21.4424H93.0674V22.9141H91.3457V27.9053C91.3457 28.6807 91.6904 29.042 92.4473 29.042C92.6516 29.0384 92.8557 29.0241 93.0586 28.999V30.4619C92.7183 30.5255 92.3725 30.5543 92.0264 30.5478C90.1934 30.5478 89.4785 29.8593 89.4785 28.1035V22.9141H88.1621V21.4424H89.4785V19.2998H91.3457Z"
          fill="white"
        />
        <path
          d="M94.0645 25.9697C94.0645 23.1211 95.7422 21.3311 98.3584 21.3311C100.983 21.3311 102.653 23.1211 102.653 25.9697C102.653 28.8262 100.992 30.6084 98.3584 30.6084C95.7255 30.6084 94.0645 28.8262 94.0645 25.9697ZM100.76 25.9697C100.76 24.0156 99.8643 22.8623 98.3584 22.8623C96.8525 22.8623 95.958 24.0244 95.958 25.9697C95.958 27.9316 96.8525 29.0762 98.3584 29.0762C99.8643 29.0762 100.76 27.9316 100.76 25.9697Z"
          fill="white"
        />
        <path
          d="M104.186 21.443H105.958V22.984H106.001C106.121 22.5027 106.403 22.0774 106.799 21.7795C107.196 21.4816 107.683 21.3293 108.179 21.3483C108.393 21.3475 108.606 21.3708 108.815 21.4176V23.1559C108.545 23.0733 108.263 23.0353 107.98 23.0436C107.71 23.0326 107.441 23.0802 107.191 23.1831C106.942 23.286 106.717 23.4417 106.533 23.6396C106.349 23.8375 106.21 24.0729 106.126 24.3296C106.041 24.5862 106.013 24.8581 106.044 25.1266V30.4967H104.186V21.443Z"
          fill="white"
        />
        <path
          d="M117.384 27.8369C117.134 29.4805 115.533 30.6084 113.485 30.6084C110.852 30.6084 109.217 28.8437 109.217 26.0127C109.217 23.1729 110.86 21.3311 113.407 21.3311C115.912 21.3311 117.487 23.0518 117.487 25.7969V26.4336H111.093V26.5459C111.063 26.8791 111.105 27.2148 111.215 27.5306C111.325 27.8464 111.501 28.1352 111.732 28.3778C111.962 28.6203 112.241 28.8111 112.551 28.9374C112.861 29.0637 113.194 29.1226 113.528 29.1103C113.967 29.1515 114.408 29.0498 114.785 28.8203C115.162 28.5909 115.454 28.246 115.619 27.8369H117.384ZM111.102 25.1348H115.628C115.645 24.8352 115.599 24.5354 115.495 24.2541C115.39 23.9729 115.228 23.7164 115.02 23.5006C114.811 23.2849 114.56 23.1145 114.283 23.0003C114.005 22.8861 113.707 22.8305 113.407 22.8369C113.105 22.8351 112.805 22.8933 112.525 23.008C112.245 23.1227 111.99 23.2918 111.776 23.5054C111.561 23.7191 111.391 23.973 111.276 24.2527C111.16 24.5323 111.101 24.8321 111.102 25.1348Z"
          fill="white"
        />
        <path
          d="M44.7378 11.2534H45.5356C45.673 11.2705 45.8124 11.259 45.945 11.2195C46.0777 11.1801 46.2008 11.1136 46.3065 11.0242C46.4122 10.9349 46.4982 10.8246 46.5592 10.7003C46.6201 10.576 46.6547 10.4405 46.6606 10.3022C46.6606 9.75193 46.2466 9.39646 45.5444 9.39646C45.4037 9.3768 45.2605 9.38553 45.1232 9.42214C44.9859 9.45875 44.8574 9.52249 44.7451 9.60961C44.6329 9.69672 44.5392 9.80544 44.4697 9.92935C44.4002 10.0533 44.3562 10.1898 44.3403 10.331H43.4643C43.4794 10.0747 43.5471 9.82428 43.6631 9.59527C43.7792 9.36626 43.9412 9.16359 44.139 8.99989C44.3367 8.83618 44.5661 8.71494 44.8128 8.64372C45.0594 8.5725 45.3181 8.55282 45.5727 8.58591C46.7475 8.58591 47.5747 9.24362 47.5747 10.1904C47.5775 10.5076 47.4714 10.8161 47.2741 11.0645C47.0768 11.3129 46.8003 11.486 46.4907 11.5551V11.6255C46.8577 11.6417 47.2033 11.8027 47.452 12.073C47.7007 12.3434 47.8323 12.7013 47.8179 13.0683C47.7907 13.3322 47.7111 13.588 47.5838 13.8208C47.4565 14.0535 47.2841 14.2585 47.0765 14.4237C46.8689 14.5889 46.6305 14.7111 46.3751 14.7829C46.1198 14.8548 45.8526 14.875 45.5894 14.8423C45.3255 14.8782 45.0572 14.8607 44.8003 14.7907C44.5434 14.7208 44.3032 14.5998 44.0941 14.4351C43.8849 14.2703 43.7111 14.0651 43.5829 13.8317C43.4548 13.5983 43.3749 13.3415 43.3481 13.0766H44.2378C44.2915 13.6474 44.8159 14.0156 45.5894 14.0156C46.3628 14.0156 46.8677 13.6021 46.8677 13.0191C46.8677 12.4277 46.3921 12.0635 45.561 12.0635H44.7378V11.2534Z"
          fill="white"
        />
        <path
          d="M48.8286 13.4238C48.8286 12.6133 49.4331 12.146 50.5034 12.0796L51.7231 12.0093V11.6206C51.7231 11.145 51.4097 10.8765 50.8013 10.8765C50.3052 10.8765 49.9624 11.0586 49.8628 11.3769H49.0024C49.0933 10.6035 49.8218 10.1074 50.8433 10.1074C51.9722 10.1074 52.6089 10.6694 52.6089 11.6206V14.6973H51.7524V14.0644H51.6821C51.5394 14.2914 51.339 14.4765 51.1014 14.6007C50.8638 14.7249 50.5974 14.7838 50.3296 14.7714C50.1406 14.7911 49.9495 14.7709 49.7687 14.7122C49.588 14.6535 49.4215 14.5576 49.2801 14.4306C49.1387 14.3037 49.0255 14.1485 48.9477 13.9751C48.8699 13.8016 48.8294 13.6139 48.8286 13.4238ZM51.7231 13.039V12.6626L50.6235 12.7329C50.0034 12.7744 49.7221 12.9853 49.7221 13.3823C49.7221 13.7876 50.0737 14.0234 50.5571 14.0234C50.6988 14.0378 50.8419 14.0235 50.9779 13.9814C51.1139 13.9393 51.24 13.8703 51.3488 13.7784C51.4576 13.6866 51.5468 13.5738 51.6111 13.4468C51.6754 13.3197 51.7135 13.1811 51.7231 13.039Z"
          fill="white"
        />
        <path
          d="M54.9673 10.9424V14.6973H54.0786V10.1943H57.0435V10.9423L54.9673 10.9424Z"
          fill="white"
        />
        <path
          d="M62.4292 12.4438C62.4292 13.8745 61.7056 14.7714 60.5689 14.7714C60.2898 14.7905 60.0111 14.7294 59.7656 14.5954C59.5201 14.4614 59.318 14.2601 59.1831 14.0151H59.1128V16.1943H58.2241V10.1943H59.0796V10.9385H59.146C59.2833 10.6849 59.4881 10.4742 59.7376 10.3297C59.9872 10.1852 60.2718 10.1125 60.5601 10.1196C61.7056 10.1196 62.4292 11.0126 62.4292 12.4438ZM61.5151 12.4438C61.5151 11.4926 61.0649 10.9179 60.312 10.9179C59.564 10.9179 59.0962 11.5048 59.0962 12.4477C59.0962 13.3862 59.564 13.9736 60.3081 13.9736C61.0649 13.9736 61.5151 13.3989 61.5151 12.4438Z"
          fill="white"
        />
        <path
          d="M63.6128 16.3101V15.5449C63.709 15.5571 63.8059 15.5627 63.9028 15.5615C64.0922 15.5755 64.28 15.5192 64.4303 15.4032C64.5806 15.2872 64.6828 15.1198 64.7173 14.9331L64.7837 14.7139L63.1538 10.1943H64.1216L65.2505 13.771H65.3208L66.4458 10.1943H67.3882L65.7095 14.8833C65.3248 15.979 64.899 16.3306 63.981 16.3306C63.9068 16.3306 63.6958 16.3223 63.6128 16.3101Z"
          fill="white"
        />
        <path
          d="M68.0806 13.4195H68.9653C69.0522 13.8165 69.4243 14.0235 69.9448 14.0235C70.5366 14.0235 70.8999 13.7628 70.8999 13.3575C70.8999 12.9234 70.5737 12.7164 69.9448 12.7164H69.271V12.026H69.9331C70.4702 12.026 70.7437 11.8194 70.7437 11.443C70.7437 11.1124 70.479 10.86 69.9331 10.86C69.436 10.86 69.0728 11.0709 69.0151 11.4264H68.1587C68.2227 11.0179 68.4445 10.6509 68.7763 10.4043C69.1082 10.1576 69.5235 10.0511 69.9331 10.1075C71.0034 10.1075 71.6157 10.5914 71.6157 11.3522C71.6207 11.564 71.5528 11.7712 71.4234 11.939C71.294 12.1068 71.1109 12.2251 70.9048 12.2741V12.3444C71.1502 12.376 71.3755 12.4967 71.5379 12.6834C71.7003 12.8702 71.7884 13.1101 71.7856 13.3575C71.7856 14.2135 71.0571 14.7799 69.9448 14.7799C68.9614 14.7799 68.1626 14.3292 68.0806 13.4195Z"
          fill="white"
        />
        <path
          d="M73.0654 14.6973V10.1943H73.9463V13.2915H74.0166L76.0918 10.1943H76.9727V14.6973H76.0918V11.5835H76.0254L73.9463 14.6973H73.0654Z"
          fill="white"
        />
        <path
          d="M81.8253 10.9423H80.4073V14.6972H79.5176V10.9423H78.0996V10.1943H81.8253V10.9423Z"
          fill="white"
        />
        <path
          d="M86.6125 13.4825C86.4914 13.894 86.2296 14.2499 85.8727 14.488C85.5159 14.7262 85.0868 14.8314 84.6603 14.7852C84.3637 14.7931 84.0688 14.7363 83.7963 14.6187C83.5238 14.5012 83.2802 14.3258 83.0823 14.1046C82.8844 13.8835 82.7369 13.6219 82.6502 13.3381C82.5636 13.0543 82.5397 12.755 82.5802 12.461C82.5407 12.1661 82.565 11.8662 82.6515 11.5815C82.738 11.2969 82.8847 11.0341 83.0815 10.811C83.2784 10.5879 83.5209 10.4098 83.7926 10.2886C84.0643 10.1674 84.3589 10.1059 84.6564 10.1085C85.9093 10.1085 86.6662 10.9645 86.6662 12.3785V12.6886H83.4865V12.7384C83.4724 12.9037 83.4931 13.0701 83.5471 13.2269C83.6012 13.3837 83.6875 13.5275 83.8004 13.649C83.9134 13.7705 84.0505 13.867 84.2029 13.9323C84.3554 13.9977 84.5198 14.0304 84.6857 14.0284C84.8981 14.0538 85.1133 14.0154 85.3039 13.9182C85.4945 13.821 85.6519 13.6694 85.756 13.4825H86.6125ZM83.4865 12.0313H85.7599C85.7711 11.8803 85.7506 11.7286 85.6998 11.5859C85.6489 11.4432 85.5687 11.3128 85.4644 11.2029C85.3601 11.0931 85.234 11.0063 85.0942 10.948C84.9544 10.8898 84.8039 10.8615 84.6525 10.8648C84.4987 10.8628 84.3461 10.8915 84.2037 10.9494C84.0613 11.0073 83.9319 11.0931 83.8232 11.2019C83.7145 11.3106 83.6287 11.4401 83.5708 11.5825C83.513 11.725 83.4843 11.8776 83.4865 12.0313Z"
          fill="white"
        />
        <path
          d="M90.3799 10.1943H92.4102C93.291 10.1943 93.8076 10.6406 93.8076 11.3393C93.809 11.561 93.7342 11.7765 93.5957 11.9496C93.4572 12.1228 93.2635 12.2431 93.0469 12.2905V12.3608C93.3099 12.3774 93.5564 12.4944 93.7356 12.6876C93.9148 12.8808 94.0129 13.1354 94.0098 13.3989C94.0098 14.1845 93.4189 14.6972 92.4307 14.6972H90.3799V10.1943ZM91.2607 10.8765V12.0425H92.1416C92.6787 12.0425 92.9356 11.8359 92.9356 11.4551C92.9356 11.104 92.7119 10.8764 92.2402 10.8764L91.2607 10.8765ZM91.2607 12.6958V14.0151H92.3145C92.8399 14.0151 93.1211 13.7832 93.1211 13.3574C93.1211 12.9067 92.8028 12.6958 92.2031 12.6958H91.2607Z"
          fill="white"
        />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_2365_1787">
        <rect
          width="135"
          height="40"
          fill="white"
        />
      </clipPath>
    </defs>
  </svg>
</template>

<script setup lang="ts">

</script>
